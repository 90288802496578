import { offset } from "@floating-ui/dom";
import { useFloating, autoUpdate } from "@floating-ui/react";
import React, { KeyboardEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { isNewNavEnabled } from "@/utils/locale";

import { UI_CONTEXTS } from "@/analytics/constants";

import { useCurrentLocale } from "@/context/LanguageContext";

import { KINDS, SIZES } from "@/components/buttons/buttons.constants";
import LinkButton from "@/components/buttons/link-button";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { ChunkedNav } from "../../../interfaces/navigation-interface";
import styles from "../navigation.module.scss";
import { NewSubNavigation } from "../new-sub-navigation/new-sub-navigation";
import { SubNavigation } from "../sub-navigation/sub-navigation";
import { TopLevelNavigationItem } from "./top-level-navigation-item/top-level-navigation-item";

export interface Props {
    navigationItems: ChunkedNav;
}

const SubNavigationItems = ({ navigationItems, activeId }) => {
    if (!activeId) {
        return null;
    }

    return navigationItems
        .filter(([navItem]) => activeId === navItem.id)
        .map(([navItem, subNavItems]) => {
            return <SubNavigation key={navItem.id} subMenuItemChunks={subNavItems} />;
        });
};

export const TopLevelNavigation: React.FC<Props> = (props: Props) => {
    const { navigationItems } = props;

    const [activeId, setActiveId] = useState(undefined);

    const handleKeyDown = (e: KeyboardEvent, id: string) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setActiveId(id);
        }
    };
    return (
        <div
            className={styles.topLevelNavigationContainer}
            onMouseLeave={() => setActiveId(undefined)}
        >
            <ul className={styles.topLevelNavigationList}>
                {navigationItems.map(([{ id, text, link, categoryId, bold }]) => (
                    <TopLevelNavigationItem
                        key={id}
                        active={activeId === id}
                        categoryId={categoryId}
                        text={text}
                        link={link}
                        bold={bold}
                        onMouseEnter={() => setActiveId(id)}
                        onKeyDown={(e) => handleKeyDown(e, id)}
                    />
                ))}
            </ul>

            <SubNavigationItems navigationItems={navigationItems} activeId={activeId} />
        </div>
    );
};

export const useAllCategoriesNav = (navigationItems: ChunkedNav) => {
    const locale = useCurrentLocale();
    const [activeId, setActiveId] = useState(undefined);
    const isNewNav = isNewNavEnabled(locale);
    const { t } = useTranslation();
    const mappedAllCategoryNav = navigationItems.filter(
        ([navItem]) => navItem.text === "All categories"
    );
    const mappedItems = mappedAllCategoryNav[0];
    const allCategoriesId = mappedItems ? mappedItems[0].id : undefined;
    const [showCategories, setShowCategories] = useState(false);
    const { refs, floatingStyles } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: "bottom-start",
        middleware: [offset(10)],
    });

    const onMouseEnter = useCallback(() => {
        setActiveId(allCategoriesId);
        setShowCategories(true);
    }, [setActiveId, allCategoriesId, setShowCategories]);

    const onMouseLeave = useCallback(() => {
        setActiveId(undefined);
        setShowCategories(false);
    }, [setShowCategories]);

    const allCategoryNav = useMemo(() => {
        const subNavigation = navigationItems.filter(([navItem]) => activeId === navItem.id);
        const subNavigationItems = subNavigation.length > 0 ? subNavigation[0][1] : [];
        return (
            <div className={styles.hoverDropdownContainer}>
                <div
                    ref={refs.setReference}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    className={styles.linkButtonContainer}
                >
                    <LinkButton
                        kind={KINDS.UNSET}
                        size={SIZES.SMALL}
                        label={t("header.allCategories.text")}
                        url={t("header.allCategories.link")}
                        newTab={false}
                        overrideFocusBorder={false}
                        uiContext={UI_CONTEXTS.TOP_NAVIGATION}
                        passHref={false}
                        forwardUtm={false}
                    />
                </div>
                <div
                    ref={refs.setFloating}
                    className="subNav"
                    style={{ ...floatingStyles, visibility: showCategories ? "visible" : "hidden" }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <NewSubNavigation subMenuItemChunks={subNavigationItems} autoFocus={false} />
                </div>
            </div>
        );
    }, [
        onMouseEnter,
        t,
        refs,
        floatingStyles,
        showCategories,
        navigationItems,
        activeId,
        onMouseLeave,
    ]);

    return {
        isNewNav,
        allCategoryNav,
        validAllCategryNav: mappedAllCategoryNav,
    };
};
