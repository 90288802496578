import { useTranslation } from "next-i18next";
import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";

import { CSP } from "@/shared/constants";

import { isProd } from "@/utils/runtime-environment";

import { Locale } from "@/types/locale";
import { PageMeta } from "@/types/page";

import SharedScripts from "./shared-scripts";

type OwnProps = {
    currentLocale: Locale;
    useOldStyles: boolean;
    preventIndexing: boolean;
};
type Props = OwnProps & PageMeta;
const SharedHead: React.FC<Props> = ({
    currentLocale,
    description,
    useOldStyles,
    title,
    preventIndexing,
}) => {
    const router = useRouter();
    const { t } = useTranslation();
    const pageTitle = title || t("title");
    const pageDescription = description || t("metaDescription");
    const localesToNoIndex = ["fr-FR", "it-IT", "es-ES"];
    const isNoIndexLocale = localesToNoIndex.includes(currentLocale);
    const noIndexMeta = (preventIndexing || isNoIndexLocale || !isProd()) && (
        <meta name="robots" content="noindex" />
    );
    const oldStyleCss = useOldStyles && (
        <link
            rel="stylesheet"
            media="screen"
            href="https://site-assets.afterpay.com/packs/application-632cf8ad97e31616e52ed210dac9a889-webpack.css"
        />
    );

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <link rel="icon" href={"/favicon.ico"} />
                <meta charSet="utf-8" />
                <meta httpEquiv="Content-Security-Policy" content={CSP} />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <meta name="description" content={pageDescription} />
                <meta
                    name="google-site-verification"
                    content="ZJMu9MsvjHJwPn0kcq31cioCrPxAw0HHWqWOqXpq1-Y"
                />
                <meta property="og:title" content={pageTitle} />

                <link rel="alternate" hrefLang={currentLocale} href={router.pathname} />

                {noIndexMeta}
                {oldStyleCss}
            </Head>
            <SharedScripts currentLocale={currentLocale} />
        </>
    );
};

export default SharedHead;
