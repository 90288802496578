import Script from "next/script";
import React, { useMemo } from "react";

import { LOCALE_TO_ONETRUST_RESOURCE_MAP } from "@/shared/constants";

import { Locale } from "@/types/locale";

const getAfterpaySdkUrl = (locale: Locale): string => {
    switch (locale) {
        case "en-GB":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_GB;
        case "en-US":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_US;
        case "en-AU":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_AU;
        case "en-NZ":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_NZ;
        case "en-CA":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_CA;
        case "fr-CA":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_FR_CA;
        case "global":
            return process.env.NEXT_PUBLIC_AFTERPAY_MERCHANT_SDK_EN_AU;
    }
};

type OwnProps = {
    currentLocale: Locale;
};
type Props = OwnProps;
const SharedScripts: React.FC<Props> = ({ currentLocale }) => {
    const oneTrustData = LOCALE_TO_ONETRUST_RESOURCE_MAP[currentLocale];
    const afterpaySdkUrl = useMemo(() => getAfterpaySdkUrl(currentLocale), [currentLocale]);

    const oneTrustScript = oneTrustData?.id && (
        <>
            <Script
                type="text/javascript"
                src={oneTrustData.otAutoBlockURL}
                strategy="beforeInteractive"
            ></Script>
            <Script
                src={oneTrustData.otSDKStubURL}
                type="text/javascript"
                charSet="UTF-8"
                data-domain-script={oneTrustData.id}
                strategy="beforeInteractive"
            ></Script>
            <Script
                type="text/javascript"
                // Inline scripts can't be load with beforeInteractive: https://nextjs.org/docs/basic-features/script
                strategy="afterInteractive"
                id="oneTrust-OptanonWrapper"
            >
                {"function OptanonWrapper() {}"}
            </Script>
        </>
    );

    return (
        <>
            {oneTrustScript}
            <Script src={afterpaySdkUrl} />
        </>
    );
};

export default SharedScripts;
